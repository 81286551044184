<template>
  <div class="questionDetails">
    <dj-crumbs :config="crumbsConfig"/>
    <div class="ques">
      <div class="ques_box">
        <p class="title">{{ obj.question }}</p>
        <p class="answer">解答 :</p>
        <quillEdit class="content" :content="obj.answer"/>
      </div>
    </div>
  </div>
</template>

<script>
import {getProblemDetails} from "@/api/product.js";
import quillEdit from "@/components/common/quill";
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";

export default {
  name: "questionDetails",
  components: {quillEdit},
  mixins: [metaInfoMixins],
  data() {
    return {
      crumbsConfig: [
        {
          url: "/",
          text: "首页"
        },
        {
          url: "/userService/question",
          text: "常见问题"
        },
        {
          text: "问题解答"
        }
      ],
      id: "",
      obj: {},
      metaInfo: {}
    };
  },
  metaInfo() {
    return this.metaInfo;
  },
  created() {
    this.id = this.$route.query.id;
    this.getProblemDetailsFun();
  },
  methods: {
    getProblemDetailsFun() {
      let params = {
        problemId: this.id
      };
      getProblemDetails(params).then((res) => {
        if (res.success) {
          this.obj = res.data;
          this.metaInfo = {
            title: res.data.tdkData.title,
            meta: [
              {
                name: "keywords",
                content: res.data.tdkData.keywords ? res.data.tdkData.keywords.join(",") : ""
              },
              {
                name: "description",
                content: res.data.tdkData.description
              }
            ]
          };
        } else {
          this.$router.replace({
            path: '/notFound'
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.questionDetails {
  .ques {
    .ques_box {
      padding: 40px 60px 80px;
      background-color: #FFFFFF;
      .title {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
        line-height: 52px;
        word-wrap: break-word;
      }
      .answer {
        font-size: 28px;
        font-weight: bold;
        color: #0064EB;
        line-height: 40px;
        padding: 40px 0 24px 0;
        font-weight: bold;
      }
      .content {
        font-size: 28px;
        word-wrap: break-word;
        /deep/ img {
          max-width: 100%;
        }
        /deep/ video {
          max-width: 100%;
        }
      }
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .questionDetails {
    .ques {
      .ques_box {
        padding: 60px 60px 80px;
        background-color: #FFFFFF;
        .title {
          font-size: 32px;
          font-weight: bold;
          color: #333333;
          line-height: 48px;
          word-wrap: break-word;
        }
        .answer {
          font-size: 24px;
          font-weight: bold;
          color: #0064EB;
          line-height: 31px;
          padding: 40px 0 24px 0;
          font-weight: bold;
        }
        .content {
          font-size: 24px;
          word-wrap: break-word;
          /deep/ img {
            max-width: 100%;
          }
          /deep/ video {
            max-width: 100%;
          }
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .questionDetails {
    .ques {
      background-color: #FFFFFF;
      .ques_box {
        width: 1200px;
        margin: 0 auto;
        padding: 40px 400px 80px 0;
        background-color: #FFFFFF;
        .title {
          font-size: 30px;
          line-height: 44px;
        }
        .answer {
          font-size: 20px;
          line-height: 26px;
          padding: 40px 0 24px 0;
        }
        .content {
          font-size: 16px;
          img {
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
